// import { Container } from '@mui/material'
import React from "react";
import ShowFriends from "../components/roundpic";
// import FriendHead from "../components/friendhead"

export default function ShowMyFriends() {
    return (
        <div>
            <ShowFriends />
        </div>
    )
}
