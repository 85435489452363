import React, {Component} from "react"
import * as roundStyle from './roundpic.module.css'
// import { Container } from '@mui/material'
import FriendHead from "./friendhead";
import head1 from '/static/images/head1.jpg'
import ClanstyHead from '/static/images/Clansty_head.webp'
import DustellaHead from '/static/images/Dustella_head.jpg'
import JulianHead from '/static/images/julian_head.jpg'
import CanyingHead from '/static/images/Canying_head.jpg'

const ShowFriends = (props) => {
    return (
        <div className={roundStyle.menu}>
            <ul>
                <li>
                    <a >
                    <FriendHead 
                    pic={ClanstyHead} 
                    name="Clansty" 
                    text="凌莞咕噜咕噜～" 
                    url="https://clansty.com/"
                    />
                    </a>
                </li>
                <li>
                    <a >
                    <FriendHead 
                    pic={JulianHead} 
                    name="Julian" 
                    text="Mon cœur est un luth suspendu;
Sitôt qu'on le touche il résonne." 
                    url="https://jurt4201.blogspot.com"
                    />
                    </a>
                </li>
                <li>
                    <a >
                    <FriendHead 
                    pic={CanyingHead} 
                    name="残影" 
                    text="" 
                    url="https://blog.nya.al/"
                    />
                    </a>
                </li>
                <li>
                    <a >
                    <FriendHead 
                    pic={DustellaHead} 
                    name="Dustella" 
                    text="慵懒的魔女，稍微会一点魔法" 
                    url="https://blog.dustella.net"
                    />
                    </a>
                </li>
                <li>
                    <a >
                    <FriendHead 
                    pic={head1} 
                    name="Cody Gua" 
                    text="helloworld" 
                    url="https://blog.codynet.work"
                    />
                    </a>
                </li>
                <li>
                    <a >
                    <FriendHead 
                    pic={head1} 
                    name="Cody Gua" 
                    text="helloworld" 
                    url="https://blog.codynet.work"
                    />
                    </a>
                </li>
                <li>
                    <a >
                    <FriendHead 
                    pic={head1} 
                    name="Cody Gua" 
                    text="helloworld" 
                    url="https://blog.codynet.work"
                    />
                    </a>
                </li>
                <li>
                    <a >
                    <FriendHead 
                    pic={head1} 
                    name="Cody Gua" 
                    text="helloworld" 
                    url="https://blog.codynet.work"
                    />
                    </a>
                </li>
            </ul>
        </div>
    )
};

export default ShowFriends;