import React from "react";
import * as headStyle from './friendhead.module.css'

export default function FriendHead(props) {
const {pic, name, text, url} = props;

    return(
    <dev {...props} className={headStyle.container}>
        <dev className={headStyle.cardHeadPic} >
            <img className={headStyle.headPic} src={pic} alt={name} />
        </dev>
        <dev className={headStyle.expandingCardOverlay} >
            <dev className={headStyle.expandingCardOverlayContent} >
                <a target="_blank" rel="noreferrer" href={url} className={headStyle.expandingCardOverlayTitle}>
                    {name}
                </a>
                <div className={headStyle.expandingCardOverlayContentText}>
                    {text}
                </div>
            </dev>
        </dev>

    </dev>
    )
}